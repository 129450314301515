<template>
  <div id="footer">
    <h1>Let's Connect on Social Media!</h1>
    <div class="icons">
   
      <router-link to="/twitter">  
         <font-awesome-icon :icon="['fab', 'twitter']"  class="fa fa-twitter"/>
      </router-link>  

      <router-link to="/instagram">
        <font-awesome-icon :icon="['fab', 'instagram']" class="fa fa-instagram"/>
      </router-link>

      <router-link to="/google">
        <font-awesome-icon :icon="['fab', 'google']" class="fa fa-google"/>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
    name: 'FooterView'
}
</script>

<style>
#footer{
    background-image: url('../assets/WashingtonBackdrop.jpeg');
    background-size: cover;
    background-repeat:no-repeat;
    background-size: 100vw 100%;
    border-top: 5px solid black;
    height: 450px;
    opacity: 0.7;

}

.fa {
    padding: 20px;
    font-size: 30px;
    width: 25px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
    margin: 100px;
  }

  .fa:hover {
    opacity: 0.7;
  }

  .fa-facebook {
    background: #3B5998;
    color: white;
    padding-left: 0%;
    
  }

  .fa-instagram {
    background: #125688;
    color: white;
  }

  .fa-twitter {
    background: #55ACEE;
    color: white;
  }

  .fa-google {
    background: #dd4b39;
    color: white;
  }
</style>