import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret)
library.add(faTwitter);
library.add(faInstagram);
library.add(faGoogle);


/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)




Vue.config.productionTip = false

new Vue({


  router,
  store,
  render: h => h(App)
}).$mount('#app')
