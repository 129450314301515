   <template>
      <div class="audio-player">
        <audio ref="audioElement">
          <source v-bind:src="require('../assets/media/SamMurieFinalCommercialDemo.mp3')" type="audio/ogg">
          Your browser does not support the audio element.
        </audio>
        <div class="player-controls">
          <button @click="toggleAudio">
            {{ isPlaying ? "Pause" : "Play" }}
          </button>
          <div class="time">
            <span>{{ formattedCurrentTime }}</span> / <span>{{ formattedDuration }}</span>
          </div>
        </div>
        <div class="progress-container">
          <input
            type="range"
            class="progress"
            v-model="currentTime"
            :max="duration"
            @input="seekAudio"
          />
        </div>
        <div class="stylized-text">Sam Murie - Commercial Demo</div>
      </div>
    </template>
    
    <script>
    export default {
      data() {
        return {
          isPlaying: false,
          currentTime: 0,
          duration: 0,
          audioSource: '/Users/ianhenderson/Documents/Personal/Software Engineering Projects/sam-murie-vo/src/assets/media/SamMurieFinalCommercialDemo.mp3', // Replace with your audio file path
        };
      },
      computed: {
        formattedCurrentTime() {
          return this.formatTime(this.currentTime);
        },
        formattedDuration() {
          return this.formatTime(this.duration);
        },
      },
      methods: {
        toggleAudio() {
          const audio = this.$refs.audioElement;
          if (this.isPlaying) {
            audio.pause();
          } else {
            audio.play();
          }
          this.isPlaying = !this.isPlaying;
        },
        seekAudio() {
          const audio = this.$refs.audioElement;
          audio.currentTime = this.currentTime;
        },
        formatTime(seconds) {
          const minutes = Math.floor(seconds / 60);
          const remainingSeconds = Math.floor(seconds % 60);
          return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
        },
      },
      mounted() {
        const audio = this.$refs.audioElement;
    
        audio.addEventListener('timeupdate', () => {
          this.currentTime = audio.currentTime;
          this.duration = audio.duration;
        });
    
        audio.addEventListener('ended', () => {
          this.isPlaying = false;
        });
      },
    };
    </script>
    
    <style scoped>
    .audio-player {
      text-align: center;
      margin: 20px;
      border: 4px solid black;
      place-self: center;
      margin-left: 25%;
      padding: 25px;
      background-color: whitesmoke;
    }
    
    .player-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }
    
    button {
      background-color: rgb(25, 82, 25);;
      color: white;
      border: none;
      border-radius: 4px;
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
    }
    
    button:hover {
      background-color: rgb(251, 181, 48);
    }
    
    .time {
      margin: 0 10px;
    }
    
    .progress-container {
      margin-top: 10px;
    }
    
    .progress {
      width: 100%;
    }

    .stylized-text {
  font-family: 'Arial', sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: rgb(25, 82, 25);;/* Blue color, you can change this */
  text-align: center;
  text-transform: uppercase;
}
    </style>