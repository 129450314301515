<template>
  <div id="header">
    <div id="imageContainer">
    <img v-bind:src="require('../assets/image0.jpeg')" id="header-image"/>
    <nav id="navBar">
      <router-link to="/">Home</router-link> |
      <router-link to="/contact">Contact</router-link>
    </nav>
  </div>
  </div>
</template>

<script>

    

export default {
    name: 'HeaderView',
  
    
}
</script>

<style>
body
{
margin:0px;
}

#imageContainer{
  align-content: left;
  background-color: black;
  height: 250px;
  display: flex;
  align-items: flex-end;
  width: 100vw;
}

#header-image{
  height: 250px;
  width: 400px;
  float: left;
  padding-right: 25%;
  padding-left: 50px;
}

nav{
  justify-content: space-between;
}
nav a {
  font-weight: bold;
  color: rgb(251, 181, 48);
  font-size: 75px;
  text-decoration: none;
  padding-left: 25px;
  
  
}

nav a:hover {
  color: rgb(25, 82, 25);
}
</style>