<template>
  <div>
    <div id="description-container">
            <section>
                <p>Hi there! My name is Sam Murie (he/him) and I am a Caucasian actor, based in L.A..</p>
                <p> Nothing brings me more joy than performing. After studying Theater and Education at Central
                    Washington University, I was able to pursue theater opportunities through IATSE and several
                    local children’s theaters.</p>
                <p> In 2017, I moved from my home state of Washington to pursue acting in California. Luckily, I
                    was able to land my dream job at Disney’s California Adventure lending my voice to the one and
                    only Crush the Sea Turtle at “Turtle Talk with Crush”. In just 6 years, I have been able to
                    perform for hundreds of thousands of guests. This has afforded me the opportunity to learn the
                    tricks of the voiceover trade, find avenues to further expand my skillset, and above all, how to
                    have FUN by making an emotional connection with my audience. In that pursuit, I have been
                    fortunate enough to study with voiceover powerhouses, including: Richard Horvitz, Dmitri
                    Michas, Tish Hicks, Kelly Moscinski, and many more.</p>
                <p> In my free time, you can find me enjoying time with friends and family, playing games of all
                    shapes and sizes, trying to master the art of Pickleball, attending local BattleBots events, and
                    taking in some of the most amazing amusement parks located right in my backyard.
                    If you’re looking for the voice of your trustworthy everyman, the gentle guide, or the high
                    energy enthusiast, then I’ve got you covered. If you have any questions, please do not hesitate to
                    contact me. To hear more of my work, I encourage you to listen to my Demos, located to the left.</p>
            </section>
        </div>
  </div>
</template>

<script>
export default {
    name: 'AboutView'
}
</script>

<style>
body{
    background-color: lightgray;
}

 #description-container{
  width: 40%;
  background-color: whitesmoke;
  margin: 25px;
  padding: 50px;
  border: 4px solid black;
  justify-content: center;
  margin-left: 200px;
 }
</style>