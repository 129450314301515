<template>
  <div class="contact">
    <header-view id="header"></header-view>
    <main id="main-div">
    <h2 id="contact-header">Contact</h2>
      <emailjs></emailjs>

 
    <form id="contact-form" ref="form" @submit.prevent="sendEmail">
        <label class="contact-form-label"> First Name:
        <input type="text" v-model="firstName" name="firstName" placeholder="First" />
        </label>
        <label class="contact-form-label"> Last Name:
        <input type="text" v-model="lastName" name="lastName" placeholder="Last" />
        </label>
        <label class="contact-form-label"> Email:
        <input type="email" v-model="email" name="email" placeholder="Email" />
        </label>
        <label class="contact-form-label"> Message:
        <input type="text" v-model="message" name="message" id="concerns" placeholder="" />
        </label>
        <br>
        <input type="submit" value="Send" id="submit-button">
    </form>
   
  </main>
    <footer-view-vue id="footer"></footer-view-vue>
  
  </div>
</template>

<script>

import HeaderView from './HeaderView.vue';
import FooterViewVue from './FooterView.vue';
import emailjs from 'emailjs-com'; 

export default {

  name: 'ContactView',
    components: {
    FooterViewVue,
    HeaderView,
    emailjs
    },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      message: ''
    }
  },
  methods: {
    sendEmail() {
      try {
        emailjs.sendForm('service_wr1qyb8', 'template_6d9w20y', this.$refs.form,
        'hEyRWctJMpmeXNVCK', {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          message: this.message
        })

      } catch(error) {
          console.log({error})
      }
      // Reset form field
      this.firstName = ''
      this.lastName = ''
      this.email = ''
      this.message = ''
    },
  }
}


</script>

<style>
.home{
  display: grid;
  grid-template-areas: 
    "header header"
    "contact contact"
    "footer footer";
  height: 100vh;
  width: 100vw;
}

#header{
  grid-area: header;
}

#contact{
  grid-area: contact;
}


#footer{
  grid-area: footer;
}

:root {
    --primary-color: lightgray;
    --secondary-color: #85C4FF;
    --third-color: #ffffff;
    --fourth-color: #000000;
    --fifth-color: #e0e0e0;
    --sixth-color: #db0f0f;
    --seventh-color: #8a2020;
    --eighth-color: #ffc107;
}





#contact-form {
    background-color: var(--primary-color);
    padding: 1em;
    padding-bottom: 22em;

}

.contact-form-label {
    background-color: white;
    color: black;
    display: flex;
    width: 400px;
    margin: 1em auto;
    padding: .5em;
    gap: 10px;
    border-radius: 10px;

}

#concerns {
    height: 10vh;
    width: 100%;
    text-align: start;
    

}

#submit-button {
    background-color: white;
    padding: 1em;
    display: flex;
    width: 400px;
    margin: 1em auto;
    justify-content: center;
    border-radius: 10px;
}

#submit-button:hover {
  color: rgb(251, 181, 48);
  background-color: rgb(25, 82, 25);
  border: 2px solid rgb(251, 181, 48);
}


</style>