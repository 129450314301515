<template>
  <div class="home">
    <header-view id="header"></header-view>
    <about-view id="about"></about-view>
    <audio-view-vue id="audio"></audio-view-vue>
    <footer-view-vue id="footer"></footer-view-vue>
  </div>
</template>

<script>

import HeaderView from './HeaderView.vue';
import FooterViewVue from './FooterView.vue';
import AboutView from './AboutView.vue';
import AudioViewVue from './AudioView.vue';



export default {
  name: 'HomeView',
  components: {
    FooterViewVue,
    HeaderView,
    AudioViewVue,
    AboutView
  
  }
}
</script>
<style scoped>
.home{
  display: grid;
  grid-template-areas: 
    "header header"
    "audio about"
    "footer footer";
  height: 100vh;
  width: 100vw;
}

#header{
  grid-area: header;
}

#audio{
  grid-area: audio;
}

#about{
  grid-area: about;
}

#footer{
  grid-area: footer;
}

</style>
